import './App.css';

function App() {
  return (
    <div className="App">
      <img className="god" alt="Gods Will" src="http://newstatesman.com/sites/default/files/blogs_2014/11/2014_44_nature.jpg"></img>
    </div>
  );
}

export default App;
